import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../../base/constant";


export const amenityListApi = createApi({
    reducerPath: "amenity",
    baseQuery: fetchBaseQuery({
        baseUrl: config().BASE_URL,
        prepareHeaders: (headers: any) => {
            headers.set("Authorization", `Token ${localStorage.getItem('token')}`)
            return headers
        }
    }),
    tagTypes: ['amenity'],
    endpoints: (builder) => ({
        getAllListOfAmenity: builder.query<any, void>({
            query: () => ({
                url: "/amenity-booking/?amenities=1/",
                method: "GET",
            }),
            providesTags: ['amenity']
        }),
        getAllListOfBooking: builder.query<any, void>({
            query: () => ({
                url: "/amenity-booking/",
                method: "GET",
            }),
            providesTags: ['amenity']
        }),
        
        
        getSingleListOfAmenity: builder.query<any, void>({
            query: (id) => ({
                url: `/amenity/${id}`,
                method: "GET",
            }),
            providesTags: ['amenity']
        }),
        
        AddJoinAmenity: builder.mutation<any, object>({
            query: (joinAmenityInfo: object) => ({
              url: "/amenity-booking/",
              method: "POST",
              body: joinAmenityInfo,
            }),
            invalidatesTags: ["amenity"],
          }),
          updateAmenity: builder.mutation<any, any>({
            query: (amenityData:any) => ({
                url: `/amenity-booking/${amenityData.id}/`,
                method: "PATCH",
                body: amenityData.formData,
            }),
            invalidatesTags: ['amenity']
        }),


    }),

});

export const { useGetAllListOfAmenityQuery,useGetAllListOfBookingQuery ,useAddJoinAmenityMutation,useGetSingleListOfAmenityQuery,useUpdateAmenityMutation} = amenityListApi;
