import "./App.scss";
import "../src/assets/scss/base.scss";
import React, { Suspense, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { PATH } from "./route/path";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import Loading from "./pages/miscellaneous/loading/index"
import Index from "./pages/miscellaneous/loading/index";
import { message, notification } from 'antd';
import { useAppDispatch, useAppSelector } from "./base/hooks/hooks";
import { setMessageNotify } from "./base/reducer/message";
const Login = React.lazy(() => import("./pages/entry/login/index"));
const Signup = React.lazy(() => import("./pages/entry/signup/index"));
const PropertyDetails = React.lazy(() => import("./pages/propertyDetails/index"));
const Registration = React.lazy(() => import("./pages/houseRegistration/ApplyRegistration/index"));
const Support = React.lazy(() => import("./pages/casaSupport/addSupport/index"));
const Referral = React.lazy(() => import("./pages/Refferal/addReferral/index"));
const ReferralList = React.lazy(() => import("./pages/Refferal/referralList/index"));
const SupportList = React.lazy(() => import("./pages/casaSupport/supportList/index"));
const ProductDetails = React.lazy(() => import("./pages/enrichCommunityEnrichDetail/index"));
const CustomerLayout = React.lazy(() => import("./layouts/customerLayout/index"));
const Home = React.lazy(() => import("./pages/home/index"));
const PurchaseCart = React.lazy(() => import("./pages/purchaseCart/index"));
const MyProfile = React.lazy(() => import("./pages/myProfile/index"));
const Calendar = React.lazy(() => import("../src/pages/calendar/index"));
const CommunityEnrichList = React.lazy(() => import("./pages/enrichCommunityEnrichList/index"));
const AddProduct = React.lazy(() => import("./pages/communityEnrich/addproduct/index"));
const Visitor = React.lazy(() => import("./pages/visitor/index"));
const CasaPoints = React.lazy(() => import("./pages/casaPoints/index"));
const YourOrder = React.lazy(() => import("./pages/yourOrder/index"));
const EventJoin = React.lazy(() => import("./pages/associationEvents/eventJoin/index"));
const EventCreation = React.lazy(() => import("./pages/associationEvents/eventCreation/index"));
const EventList = React.lazy(() => import("./pages/associationEvents/eventList/index"));
const ForgetPassword = React.lazy(() => import("./pages/entry/forgetpassword/index"));
const NotFound = React.lazy(() => import("./pages/miscellaneous/notFound/index"))
const AddTenant = React.lazy(() => import("./pages/tenant/index"));
const SosContact = React.lazy(() => import("./pages/sosContact/index"));
const EnquiryList = React.lazy(() => import("./pages/communityEnrich/enquiryList/index"));
const MyEnquiryList = React.lazy(() => import("./pages/communityEnrich/myEnquiryList"))
const Payment = React.lazy(() => import("./pages/payment"))
const Services = React.lazy(() => import("./pages/enrichService/serviceCategoryList/index"))
const ServiceList = React.lazy(() => import("./pages/enrichService/serviceList/index"))
const ServiceDetails = React.lazy(() => import("./pages/enrichService/serviceDetails/index"))
const AmenitiesList = React.lazy(() => import("./pages/amenitiesList/index"))
const MaintenanceDue = React.lazy(() => import("./pages/maintenanceDue/index"))
const SummaryPage = React.lazy(() => import("./pages/summaryPage/index"))
const ForumChat = React.lazy(() => import("./pages/forumChat/index"))
const AddPoll = React.lazy(() => import("./pages/addPoll/index"))
const DailyHelps = React.lazy(() => import("./pages/dailyHelps/index"))
const DailyHelpList = React.lazy(() => import("./pages/dailyHelpList/index"))
const PrivacyPolicy = React.lazy(() => import("./pages/privacyPolicy/privacyPolicy"))
const AboutUs = React.lazy(() => import("./pages/aboutUs/aboutUs"))
const MyHelpers = React.lazy(() => import("./pages/dailyMyHelpers/dailyMyHelpers"))
const MyEvents = React.lazy(() => import("./pages/associationEvents/myEvents/myEvents"))
const RequestMeetingList = React.lazy(() => import("./pages/requestMeeting/index"))
const MyProductList = React.lazy(() => import("./pages/myProductList/index"))
const MyGallery = React.lazy(() => import("./pages/myGallery/index"))
const TenantList = React.lazy(() => import("./pages/tenantList/index"))
const FittedHomes = React.lazy(() => import("./pages/fittedHomes/index"))
const AddTestimonial = React.lazy(() => import("./pages/testimonial/addTestimonial/testimonial"))
const RentSellAssure = React.lazy(() => import("./pages/rentSellAssure/index"))
const TestimonialList = React.lazy(() => import("./pages/testimonial/testimonialList/testimonialList"))
const DeactivateAccount = React.lazy(() => import("./pages/deactivateAccount/index"))



// const MyVisitorList = React.lazy(() =>  import("./pages/myVisitorList/index"))
// const index = React.lazy(() => import("../src/pages/loading/index"))
// import Login from "./pages/entry/login/index"
// import Signup from "./pages/entry/signup/index"  
// import PropertyDetails from"./pages/propertyDetails/index"
// import Registration from"./pages/registration/index"
// import Support from"./pages/addSupport/index"
// import Referral from"./pages/referral/index"
// import ReferralList from"./pages/referralList/index"
// import AdminLayout from"./layouts/adminLayout/index"
// import CustomerLayout from"./layouts/customerLayout/index"
// import Home from"./pages/home/index"
// import Dashboard from "../src/pages/dashboard/index"
// import SupportList from "./pages/supportList/index"
// import ProductDetails from "./pages/productDetails/index"



function App() {
  console.log(process.env,"heyenv")
  const { messageNotify, messageInfo, messageType }: any = useAppSelector((state: any) => state.MessageContextSlice)
  const [api, contextHolder] = notification.useNotification();
  const dispatch = useAppDispatch()
  type NotificationType = 'success' | 'info' | 'warning' | 'error';
  useEffect(() => {
    if (messageNotify) {
      OpenNotificationWithIcon(messageType, messageInfo)
    }
  }, [messageNotify, messageInfo])

  const OpenNotificationWithIcon = (type: NotificationType, errorMessage: any) => {
    api[type]({
      message: type,
      description: errorMessage,
      duration: 3,
    });
    dispatch(setMessageNotify(false))
  };

  return (
    <div className="App">
      {contextHolder}

      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path={PATH.LOGIN} element={<Login />} />
          <Route path={PATH.SIGNUP} element={<Signup />} />
          <Route path={PATH.FORGET_PASSWORD_TYPE} element={<ForgetPassword />} />
          <Route path={PATH.PRIVACY_POLICY} element={<PrivacyPolicy />} />
          <Route path={PATH.DEACTIVATE_ACCOUNT} element={<DeactivateAccount />} />


          <Route element={<CustomerLayout />}>
            <Route path={PATH.HOME_CUSTOMER} element={<Home />} />
            <Route path={PATH.PROPERTY_DETAILS} element={<PropertyDetails />} />
            <Route path={PATH.REGISTRATION} element={<Registration />} />
            <Route path={PATH.CALENDAR} element={<Calendar />} />
            <Route path={PATH.ADD_SUPPORT} element={<Support />} />
            <Route path={PATH.REFERRAL} element={<Referral />} />
            <Route path={PATH.REFERRAL_LIST} element={<ReferralList />} />
            <Route path={PATH.SUPPORT_LIST} element={<SupportList />} />
            <Route path={PATH.PRODUCT_DETAILS} element={<ProductDetails />} />
            <Route path={PATH.PURCHASE_CART} element={<PurchaseCart />} />
            <Route path={PATH.MY_PROFILE} element={<MyProfile />} />
            <Route path={PATH.COMMUNITY_ENRICH_LIST} element={<CommunityEnrichList />} />
            <Route path={PATH.ADD_PRODUCT} element={<AddProduct />} />
            <Route path={PATH.ADD_VISITOR} element={<Visitor />} />
            <Route path={PATH.CASA_POINTS} element={<CasaPoints />} />
            <Route path={PATH.YOUR_ORDER} element={<YourOrder />} />
            <Route path={PATH.EVENT_JOIN} element={<EventJoin />} />
            <Route path={PATH.EVENT_CREATION} element={<EventCreation />} />
            <Route path={PATH.EVENT_LIST} element={<EventList />} />
            <Route path={PATH.ADD_TENANT} element={<AddTenant />} />
            <Route path={PATH.SOS_CONTACT} element={<SosContact />} />
            <Route path={PATH.ENQUIRY_LIST} element={<EnquiryList />} />
            <Route path={PATH.MY_ENQUIRY_LIST} element={<MyEnquiryList />} />
            <Route path={PATH.PAYMENT_INTEGRATION} element={<Payment />} />
            <Route path={PATH.SERVICES} element={<Services />} />
            <Route path={PATH.SERVICE_LIST} element={<ServiceList />} />
            <Route path={PATH.ENRICHSERVICE_DETAILS} element={<ServiceDetails />} />
            <Route path={PATH.AMENITIES_LIST} element={<AmenitiesList />} />
            <Route path={PATH.MAINTENANCE_DUE} element={<MaintenanceDue />} />
            <Route path={PATH.SUMMARY_PAGE} element={<SummaryPage />} />
            <Route path={PATH.FORUM_CHAT_TYPE} element={<ForumChat />} />
            <Route path={PATH.ADD_POLL} element={<AddPoll />} />
            <Route path={PATH.DAILY_HELPS} element={<DailyHelps />} />
            <Route path={PATH.DAILY_HELP_LIST} element={<DailyHelpList />} />
            <Route path={PATH.ABOUT_US} element={<AboutUs />} />
            <Route path={PATH.MY_HELPERS} element={<MyHelpers />} />
            <Route path={PATH.MY_EVENTS} element={<MyEvents />} />
            <Route path={PATH.REQUEST_APPOINTMENT_LIST} element={<RequestMeetingList />} />
            <Route path={PATH.MY_PRODUCT_LIST} element={<MyProductList />} />
            <Route path={PATH.MY_GALLERY} element={<MyGallery />} />
            <Route path={PATH.TENANT_LIST} element={<TenantList />} />
            <Route path={PATH.FITTED_HOMES} element={<FittedHomes />} />
            <Route path={PATH.ADD_TESTIMONIAL} element={<AddTestimonial />} />
            <Route path={PATH.RENT_SELL_ASSURE} element={<RentSellAssure />} />
            <Route path={PATH.TESTIMONIAL_LIST} element={<TestimonialList />} />




            {/* <Route path={PATH.MY_VISITOR_LIST} element={<MyVisitorList />} /> */}
          </Route>
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;

